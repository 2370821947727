import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import './Common.css';

const AboutUs = () => {
    return (
        <>
            <Header />
            <div className="aboutus">
                <img src={URL = 'https://res.cloudinary.com/sbstore/image/upload/v1653390139/aboutus_cqsal0.jpg'} alt='About Us' />
                <p className="aboutus-p">
                Idealbakery.com is a virtual on line supermarket serving customers in Faisalabad . Our extensive range of products covers almost entire range of Grocery, Health & Beauty, Skin Care, Household grocery, Crockery, Home electronics, Plastic ware and much more. New products are added frequently so that our customer’s requirements are met in full.
                </p>
               
                <p className="aboutus-p">
                    In case you do not find an item of your interest, we ask you to please inform us and we shall try to source and deliver the same.
                    You can order at sbstorefsd.com with full confidence, we ensure that the products are genuine and best quality possible. we try to ensure that you get better value for your money while shopping with us. SB Store is managed by a team of professionals, who have been in supermarket retail business for the last 13 years. Customer satisfaction is our mission and top most priority.

                </p>

            </div>
            <Footer />
        </>
    )
}

export default AboutUs;