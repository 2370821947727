
import React from 'react';

function Googlemap1(props) {
    return (
        <div >
            <iframe  src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13617.985362955957!2d73.0553609!3d31.428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6b91efa28c3a0aea!2sSB%20STORE!5e0!3m2!1sen!2s!4v1625485066101!5m2!1sen!2s"} 
           allowfullscreen="" loading="lazy" style= {{width:"100%",  height:"500px", border:0, boxShadow:"rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
        </div>
    );
}


function Googlemap2(props) {
    return (
        <div >
            <iframe  src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.282143745447!2d73.07909671514912!3d31.461423981389146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3922432ec11cbafd%3A0x7670d35a5f061329!2sSB%20Store!5e0!3m2!1sen!2s!4v1625485120651!5m2!1sen!2s"} 
          allowfullscreen="" loading="lazy" style= {{width:"100%",  height:"500px", border:0,  boxShadow:"rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
        </div>
    );
}

export {Googlemap1, Googlemap2};

