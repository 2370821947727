import React, { useState } from 'react'
import './Footer.css';
import { BiMap } from 'react-icons/bi';
import { IoIosCall } from 'react-icons/io';
import { MdOutgoingMail } from 'react-icons/md';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { Link } from "react-router-dom";
import TrackMyOrder from '../../Screens/TrackMyOrder';
import Orderhistory, { Corderhis } from '../../Screens/Orderhistory';

const Footer = () => {

    const [openModal, setOpenModal] = useState(false)


    return (
        <>
            <section className='footer-bgimg'>
                <div className='main-footer'>


                    <div>
                        <h3>ABOUT US</h3>
                        <p>
                        Idealbakery.com is perhaps the largest supermarket, serving Faisalabad.<Link to={'/aboutus'} style={{ color: 'red' }} onClick={() => { window.scrollTo(0, 0) }}> Read More </Link>
                        </p>

                        <ul className='fc1-link'>
                            <li>
                                <a href='https://goo.gl/maps/RFWEA4DXmLbNPZw96' target='_blank'> <BiMap className='map' />
                                    Gulberg, Faisalabad
                                </a>
                            </li>
                            <li>
                                <a href='tel:+923088557555'>  <IoIosCall className='map' />
                                    +92 3211616328
                                </a>
                            </li>
                            <li >
                                <a> <Link to={'/contactus'} onClick={() => { window.scrollTo(0, 0) }}><MdOutgoingMail className='map' /> sales@idealbakery.com
                                </Link></a>
                            </li>
                        </ul>

                    </div>

                    <div>
                        <h3> INFORMATION </h3>
                        <ul >
                            <li>
                                <Link to={'/aboutus'} onClick={() => { window.scrollTo(0, 0) }}>About Us</Link>
                            </li>
                            <li>
                                <Link to={'/contactus'} onClick={() => { window.scrollTo(0, 0) }}>Contact Us</Link>
                            </li>
                            <li>
                                <Link to={'/privacypolicy'} onClick={() => { window.scrollTo(0, 0) }}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to={'/termcondition'} onClick={() => { window.scrollTo(0, 0) }}>Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to={'/deliveryinfo'} onClick={() => { window.scrollTo(0, 0) }}>Delivery Information</Link>
                            </li>
                        </ul>

                    </div>
                    <div>
                        <h3> SERVICE </h3>
                        <ul >
                            <li>
                                <Link to={'/viewcart'} onClick={() => { window.scrollTo(0, 0) }}   > View Cart</Link>
                            </li>
                            <li>
                                <p className='track-myorder' onClick={() => setOpenModal(!openModal)}>
                                    Track My Order
                                </p>
                                <span style={{ position: 'relative' }} >

                                    <span className='open-ordertrack' style={{ display: openModal ? 'flex' : 'none', }}  >
                                        <TrackMyOrder closeModal={setOpenModal} />
                                    </span>
                                </span>
                            </li>
                            <li>
                                <Link to={'/checkout'} onClick={() => { window.scrollTo(0, 0) }}> Checkout</Link>
                            </li>
                            <li>
                                <Link to={'/orderhistory'} target='_blank' > Order History </Link>
                            </li>

                        </ul>
                    </div>

                    <div>
                        <h3> SOCIAL MEDIA </h3>
                        <ul >
                            <span className='social' >
                                <a href='https://www.facebook.com/sbstorefsd/' target="_blank">
                                    <FaFacebook className='facebook' />
                                </a>
                                <a href='https://instagram.com/sbstore_fsd?igshid=YmMyMTA2M2Y=' target="_blank">
                                    <FaInstagram className='instagram' />
                                </a>
                                <a href='#' target="_blank">
                                    <FaYoutube className='youtube' />
                                </a>
                            </span>
                        </ul>
                        <span className='social' >
                            <a href='https://apps.apple.com/pk/app/' target="_blank">
                                <img src={`	https://metro-online.pk/images/appstore.png`} />
                            </a>
                            <a href='https://play.google.com/store/apps/' target="_blank">
                                <img src={`https://metro-online.pk/images/googleplay.png?1`} />
                            </a>
                        </span>
                    </div>


                </div>
                <div className='copy-rights'>
                    <p>
                          © 2022 All Rights Reserved - Powered by
                        <a href='https://www.softvalley.com.pk/' target='_blank'> Softvalley</a>  
                    </p>
                </div>

            </section>
        </>
    )
}

export default Footer;